import axios from "axios";
import { Buffer } from "buffer";
import IPFS from "ipfs-only-hash";

export const MC_ADDRESS = "0x4B60cd9D2f19C12C84496fe74969DAe0701E416A";
export const VERIFICATION_SCHEMA_UID =
  "0x8a6521d338377c26a83004952b8e3c7d812b3039cb5260e0185e02e36d7d9c7b";
export const VERIFICATION_SCHEMA_STRING = "string publicationIpfsHash";
// Sepolia
export const EAS_CONFIG = {
  address: "0xA1207F3BBa224E2c9c3c6D5aF63D0eb1582Ce587",
  version: "0.26",
  chainId: 1n,
};

export async function fetchFileAndCalculateCID(
  url: string,
): Promise<string | undefined> {
  try {
    const response = await axios.get(url, { responseType: "arraybuffer" });
    const data = response.data;
    const buffer = Buffer.from(data);

    return await IPFS.of(buffer);
  } catch (error) {
    console.error("Error fetching file or calculating CID:", error);
    return undefined;
  }
}

// @ts-ignore
BigInt.prototype.toJSON = function () {
  return this.toString();
};

export async function getHtmlFileFromCloudflareByIpfsHash(ipfsHash: string) {
  const res = await axios.get(`https://eas.infura-ipfs.io/ipfs/${ipfsHash}`, {
    headers: {
      "Content-Type": "text/html",
      accept: "text/html",
    },
  });
  return res.data;
}
