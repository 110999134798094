import styled from "styled-components";
import dayjs from "dayjs";
import {
  AttestationShareablePackageObject,
  zipAndEncodeToBase64,
} from "@ethereum-attestation-service/eas-sdk";
import { QRCodeSVG } from "qrcode.react";
import { useMemo } from "react";
import { HiOutlineExternalLink } from "react-icons/hi";
import React, { useState, useEffect } from "react";
import { FaShield } from "react-icons/fa6";
import { BiCheckShield, BiSolidCheckShield } from "react-icons/bi";

const Container = styled.div`
  position: relative;
  max-width: 800px;
  padding: 32px;
  margin: 48px auto 0;
  background-color: #323232;
  width: 100%;
  box-sizing: border-box;
  border-radius: 6px;
  color: #fff;
  word-break: break-all;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  @media (max-width: 750px) {
    display: block;
  }
`;

const Logo = styled.img`
  width: 100px;
`;

const HeaderRight = styled.div``;

const Title = styled.div`
  font-weight: 500;
  color: #fff;
  font-size: 28px;
`;

const UID = styled.div`
  font-family: Helvetica;
  color: #bdbdbd;
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin-top: 20px;
  gap: 12px;

  @media (max-width: 750px) {
    display: block;
  }
`;

const Left = styled.div``;
const Right = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Details = styled.div`
  margin-bottom: 10px;
  font-size: 14px;
  color: #bdbdbd;
  word-break: normal;
`;

const Address = styled(Details)`
  font-size: 14px;
`;

const LargeDetails = styled.div`
  font-size: 14px;
  word-break: break-all;
  width: 100%;
  box-sizing: border-box;
  background-color: #454545;
  padding: 20px;
  border-radius: 4px;
  white-space: pre-wrap;
  color: #fff;
  margin: 8px 0;

  @media (max-width: 750px) {
    width: 100%;
  }
`;

const IPFS = styled.div`
  font-size: 18px;
`;

const Property = styled.div`
  font-weight: 600;
  font-size: 13px;
  color: #bdbdbd;
`;

const PropertyContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const BlockHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Value = styled.div`
  font-weight: 500;
  font-size: 16px;
`;

const AttestedDataSeparator = styled.hr`
  border: 0;
  border-top: 1px solid #595d5e;
  margin: 20px 0;
`;

const ExplorerLink = styled.a`
  display: inline-flex;
  gap: 4px;
  align-items: center;
  color: #ccc;
  font-size: 14px;

  text-decoration: none;

  &:hover {
    color: #f38b00;
  }
`;

const IPFSLink = styled.a`
  display: inline-flex;
  gap: 4px;
  align-items: center;
  color: #f9f9f9;
  font-size: 14px;

  text-decoration: none;

  &:hover {
    color: #f38b00;
  }
`;

const MCValue = styled.a`
  font-size: 20px;
  cursor: pointer;
  text-decoration: none;
  color: #f9f9f9;
  font-weight: 600;

  &:hover {
    color: #ccc;
  }
`;

const Banner = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -20px;
  width: 80%;
  left: 50%;
  transform: translateX(-50%);
  right: 0;
  height: 50px;
  background-color: #e3dfd7;
  color: #141413;
  border-radius: 4px;
`;

const OutlineIcon = styled(HiOutlineExternalLink)`
  margin-left: 3px;
`;

type Props = {
  attestationPackage: AttestationShareablePackageObject;
  ipfsHash: string;
};

export function AttestationDetails({ attestationPackage, ipfsHash }: Props) {
  const qr = useMemo(
    () => zipAndEncodeToBase64(attestationPackage),
    [attestationPackage],
  );

  const [qrSize, setQrSize] = useState(200); // Initialize with the default size
  const qrValue = useMemo(
    () => zipAndEncodeToBase64(attestationPackage),
    [attestationPackage],
  );

  // Adjust the size of the QR code based on the width of the window
  useEffect(() => {
    function handleResize() {
      // Set size to window width if it's less than 500px
      if (window.innerWidth < 500) {
        setQrSize(window.innerWidth - 40); // Subtract some pixels for padding
      } else {
        setQrSize(200); // Default size for larger screens
      }
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call the handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Container>
      <Header>
        <Logo src="/images/mc_logo.png" />
        <HeaderRight>
          <Title>Mastercard Verified Communication</Title>
          <UID>
            <b>UID:</b> {attestationPackage.sig.uid}
          </UID>
        </HeaderRight>
      </Header>

      <AttestedDataSeparator />

      <Row>
        <PropertyContainer>
          <Property>ATTESTER:</Property>
          <MCValue
            href={`https://easscan.org/address/${attestationPackage.signer}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            MastercardAttest.eth
          </MCValue>
          <Address>{attestationPackage.signer}</Address>
        </PropertyContainer>

        <PropertyContainer>
          <Property>TIMESTAMP:</Property>
          <Value>
            {dayjs
              .unix(Number(attestationPackage.sig.message.time))
              .format("MMMM D, YYYY h:mma")}{" "}
            EST
          </Value>

          <PropertyContainer>
            <Property>Revoked:</Property>
            <Value>No</Value>
          </PropertyContainer>
        </PropertyContainer>

        <PropertyContainer>
          <Property>Expired:</Property>
          <Value>No</Value>
        </PropertyContainer>

        <QRCodeSVG
          style={{
            width: "100%",
            height: "auto",
            maxWidth: `${qrSize}px`,
          }}
          value={`https://easscan.org/offchain/url/#attestation=${encodeURIComponent(
            qr,
          )}`}
          includeMargin={true}
        />
      </Row>

      <AttestedDataSeparator />

      <>
        <Property>VERIFIED HASH OF EMAIL CONTENT:</Property>

        <PropertyContainer>
          <LargeDetails>
            <IPFSLink
              target="_blank"
              href={`https://eas.infura-ipfs.io/ipfs/${ipfsHash}`}
            >
              <BiSolidCheckShield color={"#91b51d"} size={24} />
              <IPFS>{ipfsHash}</IPFS>
            </IPFSLink>
          </LargeDetails>
          <Details>
            <i>
              This Is a attestation signed by Mastercard controlled wallet
              address. The content of the email was hashed and then signed to
              prove it's authenticity and immutability.
            </i>
          </Details>
        </PropertyContainer>
      </>
      <AttestedDataSeparator />

      {/*<>*/}
      {/*  <PropertyContainer>*/}
      {/*    <Property>EXTERNAL VERIFICATION:</Property>*/}
      {/*    <Details>*/}
      {/*      Verify this attestation on the Ethereum Attestation Service{" "}*/}
      {/*    </Details>*/}
      {/*  </PropertyContainer>*/}
      {/*  <PropertyContainer>*/}
      {/*    <LargeDetails>*/}
      {/*      <ExplorerLink*/}
      {/*        href={`https://sepolia.easscan.org/offchain/url/#attestation=${encodeURIComponent(*/}
      {/*          qr,*/}
      {/*        )}`}*/}
      {/*        target="_blank"*/}
      {/*      >*/}
      {/*        Verify on the EAS Explorer <OutlineIcon />*/}
      {/*      </ExplorerLink>*/}
      {/*    </LargeDetails>*/}
      {/*    <LargeDetails>*/}
      {/*      <QRCodeSVG*/}
      {/*        style={{*/}
      {/*          width: "100%",*/}
      {/*          height: "auto",*/}
      {/*          maxWidth: `${qrSize}px`,*/}
      {/*        }}*/}
      {/*        value={qrValue}*/}
      {/*        includeMargin={true}*/}
      {/*      />*/}
      {/*    </LargeDetails>*/}
      {/*  </PropertyContainer>*/}
      {/*</>*/}
      {/*<AttestedDataSeparator />*/}

      <>
        <BlockHeaderContainer>
          <Property>RAW ATTESTATION DETAILS:</Property>
          <ExplorerLink
            href={`https://easscan.org/offchain/url/#attestation=${encodeURIComponent(
              qr,
            )}`}
            target="_blank"
          >
            Verified on Ethereum Attestation Service <OutlineIcon />
          </ExplorerLink>
        </BlockHeaderContainer>
        <PropertyContainer>
          <LargeDetails>
            {JSON.stringify(attestationPackage, null, 2)}
          </LargeDetails>
        </PropertyContainer>
      </>
    </Container>
  );
}
