import React from "react";
import "./App.css";
import styled from "styled-components";
import { Centered } from "./styles";

const Container = styled.div`
  font-family: "MarkForMC", sans-serif;
  width: 100%;
`;

const MCLogo = styled.img`
  width: 180px;
`;

const MCLogoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
`;

const Title = styled.div`
  font-weight: 400;
  color: #fff;
  font-size: 44px;
  text-align: center;
  padding: 0 20px;
`;

function Home() {
  return (
    <Container>
      {/*<MCLogoContainer>*/}
      {/*  <MCLogo src="/images/mc_logo.png" alt="Mooncoin Logo" />*/}
      {/*</MCLogoContainer>*/}

      <Centered>
        <Title>Verifications.</Title>
      </Centered>
    </Container>
  );
}

export default Home;
