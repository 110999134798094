import React, { useEffect, useState } from "react";
import "./App.css";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import {
  AttestationShareablePackageObject,
  decodeBase64ZippedBase64,
  EAS,
  Offchain,
  SchemaEncoder,
} from "@ethereum-attestation-service/eas-sdk";
import {
  EAS_CONFIG,
  MC_ADDRESS,
  VERIFICATION_SCHEMA_UID,
  VERIFICATION_SCHEMA_STRING,
  fetchFileAndCalculateCID,
} from "./utils";
import { AttestationDetails } from "./AttestationDetails";
import { Centered } from "./styles";
import { VerificationSectionSelector } from "./VerificationSectionSelector";

const Container = styled.div`
  font-family: "MarkForMC", sans-serif;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
`;

const MCLogo = styled.img`
  width: 180px;
`;

const MCLogoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
`;

const Title = styled.div`
  font-weight: 400;
  color: #fff;
  font-size: 44px;
  text-align: center;
  padding: 0 20px;
`;

const Subtitle = styled.div`
  color: #aaa;
  font-size: 20px;
  text-align: center;
  padding: 0 20px;
  max-width: 600px;
  margin-top: 20px;
`;

const EmailContainer = styled.div`
  max-width: 800px;
  background-color: #f5f5f5;
  position: relative;
  margin: 60px auto 20px;
  border-radius: 6px;
  font-family: Helvetica, sans-serif;
  padding-top: 30px;
`;

// const EmailContent = styled.div`
//   background-color: #fff;
//   padding: 20px;
//   border-radius: 6px;
// `;

const SafeBanner = styled.div`
  background-color: #72a94f;
  color: #fff;
  padding: 16px 20px;
  box-sizing: border-box;
  font-size: 16px;
  text-align: center;
  position: absolute;
  width: 80%;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 4px;
`;

const Frame = styled.iframe`
  width: ${Math.min(window.innerWidth - 40, 800)}px;
  height: 800px;
  border: none;
  border-radius: 4px;
`;

const eas = new EAS(EAS_CONFIG.address);
const offchain = new Offchain(EAS_CONFIG, 2, eas);

const pageItems = ["Email Preview", "View Attestation"];

function Verify() {
  const location = useLocation();
  const [packageObject, setPackageObject] =
    useState<AttestationShareablePackageObject | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<null | "FAIL">(null);
  const [ipfsHash, setIpfsHash] = useState<string | null>(null);
  const [selectedPage, setSelectedPage] = useState(0);

  async function loadAttestation() {
    setLoading(true);
    try {
      setError(null);
      const query = "?" + location.hash.slice(1);
      const urlParams = new URLSearchParams(query);
      const attestation = decodeURIComponent(
        urlParams.get("attestation") as string,
      );
      if (attestation) {
        const decoded = decodeBase64ZippedBase64(attestation);

        const verified = offchain.verifyOffchainAttestationSignature(
          decoded.signer,
          decoded.sig,
        );

        if (!verified) throw new Error("Signature verification failed");

        if (decoded.signer !== MC_ADDRESS)
          throw new Error("Signer is not Mastercard");
        if (decoded.sig.message.schema !== VERIFICATION_SCHEMA_UID)
          throw new Error("Schema is not verification schema");

        const schemaEncoder = new SchemaEncoder(VERIFICATION_SCHEMA_STRING);

        const decodedSchema = schemaEncoder.decodeData(
          decoded.sig.message.data,
        );

        const ipfsHash = decodedSchema[0].value.value;

        if (typeof ipfsHash !== "string") throw new Error("Invalid ipfs hash");

        const CID = await fetchFileAndCalculateCID(
          `https://eas.infura-ipfs.io/ipfs/${ipfsHash}`,
        );

        if (CID !== ipfsHash) throw new Error("Invalid CID!");

        setIpfsHash(ipfsHash);
        setPackageObject(decoded);
      }
    } catch (e) {
      console.log("err", e);

      setError("FAIL");
    }

    setLoading(false);
  }

  useEffect(() => {
    loadAttestation();
  }, [location.hash]);

  return (
    <Container>
      <MCLogoContainer>
        <MCLogo src="/images/mc_logo.png" alt="Mooncoin Logo" />
      </MCLogoContainer>

      <Centered>
        <Title>Attested by Mastercard.</Title>
      </Centered>

      {loading ? (
        <></>
      ) : error ? (
        <Centered>
          <Subtitle>Something went wrong with verification.</Subtitle>
        </Centered>
      ) : packageObject && ipfsHash ? (
        <>
          <Centered>
            <Subtitle>
              Experience the confidence of verified communications with
              Mastercard's attestation service.
            </Subtitle>

            <VerificationSectionSelector
              selectedId={selectedPage}
              handleSelect={setSelectedPage}
              items={pageItems}
            />

            {selectedPage === 0 ? (
              <EmailContainer>
                <SafeBanner>
                  <b>Verified!</b> This email is safe to engage with.
                </SafeBanner>

                <Frame
                  src={`https://eas.infura-ipfs.io/ipfs/${ipfsHash}`}
                  title="email"
                />
              </EmailContainer>
            ) : (
              <AttestationDetails
                attestationPackage={packageObject}
                ipfsHash={ipfsHash}
              />
            )}
          </Centered>
        </>
      ) : (
        <>
          <Centered>
            {" "}
            <Subtitle>Something went wrong!</Subtitle>
          </Centered>
        </>
      )}
    </Container>
  );
}

export default Verify;
