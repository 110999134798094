import styled from "styled-components";
import { Centered } from "./styles";

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 20px;

  @media (max-width: 750px) {
    flex-direction: column;
  }
`;

type ItemProps = {
  selected: boolean;
};

const Item = styled.div<ItemProps>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  width: 200px;
  height: 50px;
  background-color: ${(props) => (props.selected ? "#f79e1a" : "#2c2c2c")};
  color: ${(props) => (props.selected ? "#fff" : "#ccc")};
  cursor: pointer;
  user-select: none;
  font-weight: 400;
  font-size: 16px;

  &:hover {
    background-color: ${(props) => (props.selected ? "#f79e1a" : "#3c3c3c")};
  }
`;

type Props = {
  items: string[];
  handleSelect: (id: number) => void;
  selectedId: number;
};

export function VerificationSectionSelector({
  items,
  handleSelect,
  selectedId,
}: Props) {
  return (
    <Centered>
      <Container>
        {items.map((item, index) => (
          <Item
            key={index}
            onClick={() => handleSelect(index)}
            selected={selectedId === index}
          >
            {item}
          </Item>
        ))}
      </Container>
    </Centered>
  );
}
